import * as React from "react"
import { Link, graphql } from "gatsby"

import Incident from "../components/incident"
import Layout from "../components/layout"
import Seo from "../components/seo"

const BlogIndex = ({ data, location }) => {
  const posts = data.allMarkdownRemark.nodes
  const first = posts[0]
  const siteTitle = first.Name

  if (posts.length === 0) {
    return (
      <Layout location={location} incident={siteTitle}>
        <Seo title="All posts" />
        <Incident incident={first}/>
        <p>
          No blog posts found. Add markdown posts to "content/blog" (or the
          directory you specified for the "gatsby-source-filesystem" plugin in
          gatsby-config.js).
        </p>
      </Layout>
    )
  }

  return (
    <Layout location={location} incident={siteTitle}>
      <Seo title="All posts" />
      <Incident incident={first}/>
      <div>It was not a <Link to="/glossary#cyberattack">cyberattack.</Link> </div>
      <div>All <Link to="/list">incidents</Link>| Show me a <Link to="/glossary#cyberattack">random incident</Link></div>
      
    </Layout>
  )
}

export default BlogIndex

export const pageQuery = graphql`
  query {
    site {
      siteMetadata {
        metaTitle
      }
    }
    allMarkdownRemark(sort: { fields: [frontmatter___Date], order: DESC }, filter: {frontmatter: {Name: {ne: ""}}}) {
      nodes {
        fields {
          slug
        }
        frontmatter {
          Attack
          Name
          Date
          Group
        }
      }
    }
  }
`
