import * as React from "react"
import { Link } from "gatsby"

const Layout = ({ location, slug, incident, children }) => {
 
 const rootPath = `${__PATH_PREFIX__}/`
  const isRootPath = location.pathname === rootPath
  let header

  if (isRootPath) {
    header = (
      <h1 className="main-heading">
        Was <Link className="incident" to={slug}>{incident}</Link> a <Link className="definition" to="/what">Cyber Attack?</Link>
      </h1>
    )
  } else {
    header = (
      <div>
      <Link className="header-link-home" to="/">
         Is This A Cyber Attack?
      </Link>
      <h1 className="main-heading">
        Was <Link className="incident" to={location.pathname}>{incident}</Link> a <Link className="definition" to="/what">Cyber Attack?</Link>
      </h1>
      </div>
    )
  }
/*  // <footer>
      //   © {new Date().getFullYear()} a <a href="https://www.gatsbyjs.com">tinfoil_globe</a> project
      // </footer>*/
  return (
    <div className="global-wrapper">
      <header className="global-header">{header}</header>
      <main>{children}</main>

      <footer style={{textAlign: "right"}}>suggest an incident by tagging <a href="https://twitter.com/is_cyberattack">@is_cyberattack</a> on Twitter | <Link to="/what#credits">credits</Link> </footer>
    </div>
  )
}

export default Layout
