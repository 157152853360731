/**
 * Bio component that queries for data
 * with Gatsby's useStaticQuery component
 *
 * See: https://www.gatsbyjs.com/docs/use-static-query/
 */

import * as React from "react"
import { Link } from "gatsby"


const Define = (incident) => {
  const name = incident.Name
  const cyberOp = incident.CyberOperation
  const damage = incident.ReasonablyExpectedToCauseDeathInjuryToPeopleObjects
  const verdict = incident.Attack

  const copy = verdict === "Yes" ? <b>matches</b> : <b>does not match</b>
  const ynToEmoji = (yn) =>  yn === "Yes" ? "✅" : "❌"
  let extra

  if(verdict !== "Yes") {
    extra = <span> a list of <Link to="/attacks">actual cyberattacks</Link> and</span>
  }

  return <div>
    <blockquote>A cyber attack is a cyber operation, whether offensive or defensive, that is reasonably expected to
      cause injury or death to persons or damage or destruction to objects.<br/><br/>

      <a
        href="https://www.cambridge.org/ie/academic/subjects/law/humanitarian-law/tallinn-manual-international-law-applicable-cyber-warfare?format=AR">Michael
        Schmitt et al; Tallinn Manual 1.0, pp 92</a>
    </blockquote>
    <ul style={{ listStyle: `none` }}>
      <li>{ynToEmoji(cyberOp)} Cyber Operation?</li>
      <li>{ynToEmoji(damage)} Expected to cause death or injury to people or damage to objects? </li>
    </ul>
    <div>{name} {copy} this definition. Here's {extra} and <a href="https://www.cyberscoop.com/cyber-pearl-harbor-911-cyberwar-hacking-leon-panetta-ciaran-martin/"> why this matters.</a> </div>
  </div>
}

const Incident = ({incident}) => {
  const verdict = incident.Attack || `No`
  const color = verdict === "No" ? null: "color-yes"
  return <div><div id="verdict"><h1 className={color}>{verdict}</h1></div>{Define(incident)}</div>
  
}

export default Incident
